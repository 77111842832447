// Body
$body-bg: #f8fafc;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$mostly-white-red: #ffefef;
$dark-cyan-blue: #212529;
$dark-medium-grey: #404040;
