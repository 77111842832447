/* IMPORTS */

@import 'variables';

aside#toc {
  position: fixed;

  nav#TableOfContents {

    ul {
      list-style: none;

      li {

        a {
          color: $dark-medium-grey;
          text-decoration: none; }

        a:after {
          content: '»';
          opacity: 0;
          right: -20px;
          transition: 0.5s; }

        a:hover {
          color: $blue;
          text-decoration: none;
          padding-right: 24px;
          padding-left: 8px; }

        a:hover:after {
          opacity: 1;
          right: 10px; } } } } }

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; }

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); }

.container {
  padding: 2px 16px; }

.posts-list.row {
  display: flex;
  flex-wrap: wrap;

  .col-lg-6.col-md-12 {
    flex-grow: 1;
    flex-basis: 50%;

    .media.post-preview.card {
      height: 100%; } } }
